<template>
  <div id="register">
    <div class="content-box">
      <div class="inner">
        <div class="register-main">
          <div>
            <ProgressPad :userProgress="userProgress"></ProgressPad>
            <div class="form-box">
              <div class="main">
                <h3>{{ showPage.name }}</h3>
                <component
                  ref="page"
                  :is="showPage.component"
                  :showPageNumber="showPageNumber"
                  @addPage="addPage"
                  @skipNextStep="skipNextStep"
                ></component>
                <div class="bottom">
                  <el-button v-if="showPageNumber > 1" class="btn-default" @click="reducePage" data-testid="back">
                    {{ this.$t('register.btn.back') }}
                  </el-button>
                  <LoadingButton :text="$t('register.btn.next')" :callback="submit" data-testid="next"></LoadingButton>
                </div>
              </div>
            </div>
          </div>
          <div class="progress-box">
            <el-steps direction="vertical" :active="registerStep - 1" flex-basis="10px" class="tab">
              <el-step
                :title="$t('register.tabs.personalDetails')"
                space="100"
                :class="{ active: showPage.id == 1 || showPage.id == 2, finish: registerStep > 2 }"
              ></el-step>
              <el-step
                :title="$t('register.tabs.moreAboutYou')"
                :class="{ active: showPage.id == 3, finish: registerStep > 3 }"
              ></el-step>
              <el-step
                :title="$t('register.tabs.accountConfig')"
                :class="{ active: showPage.id == 4, finish: registerStep > 4 }"
              ></el-step>
              <el-step
                :title="$t('register.tabs.confirmYourId')"
                :class="{ active: showPage.id == 5, finish: registerStep > 5 }"
              ></el-step>
              <el-step :title="$t('register.tabs.fundYourAcc')"></el-step>
            </el-steps>
            <Progress :userProgress="userProgress"></Progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalDetails from '@/components/register/PersonalDetails';
import MoreAboutYou from '@/components/register/MoreAboutYou';
import AccountConfiguration from '@/components/register/AccountConfiguration';
import ConfirmID from '@/components/register/ConfirmID';
import Progress from '@/components/register/Progress';
import ProgressPad from '@/components/register/Progress-pad';
import LoadingButton from '@/components/LoadingButton';
import registerMixins from '@/mixins/register';

export default {
  data() {
    return {
      progressList: [
        {
          name: this.$t('register.tabs.personalDetails'),
          id: 1,
          progress: 25,
          minutes: 5,
          component: 'PersonalDetails'
        },
        {
          name: this.$t('register.personalDetails.page2.pageTitle'),
          id: 2,
          progress: 35,
          minutes: 4,
          component: 'PersonalDetails'
        },
        {
          name: this.$t('register.moreAboutYou.pageTitle1'),
          id: 3,
          progress: 55,
          minutes: 3,
          component: 'MoreAboutYou'
        },
        {
          name: this.$t('register.tabs.accountConfig'),
          id: 4,
          progress: 80,
          minutes: 2,
          component: 'AccountConfiguration'
        },
        {
          name: this.$t('register.tabs.confirmYourId'),
          id: 5,
          progress: 90,
          minutes: 1,
          component: 'ConfirmID'
        },
        {
          id: 6,
          progress: 100,
          name: this.$t('register.tabs.fundYourAcc')
        }
      ]
    };
  },
  mixins: [registerMixins],
  components: {
    PersonalDetails,
    MoreAboutYou,
    AccountConfiguration,
    ConfirmID,
    Progress,
    ProgressPad,
    LoadingButton
  },
  provide() {
    return {
      onIdPlayerReady: this.onIdPlayerReady,
      onPoaPlayerReady: this.onPoaPlayerReady
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/register.scss';
</style>
